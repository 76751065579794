import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { axiosInstance } from "../services/AxiosInstance";

// Define a cache for translations to avoid unnecessary requests
const translationsCache = {};

// Function to load translations and cache them
const loadTranslations = async (language) => {
  if (translationsCache[language]) {
    return translationsCache[language];
  }

  try {
    const response = await axiosInstance.get(
      `/sorted_translations?lang=${language}`
    );
    const resources = response.data;
    translationsCache[language] = resources;
    return resources;
  } catch (error) {
    console.log(error);
    return {};
  }
};


i18n
  .use(LanguageDetector)
  .use(backend)
  .init({
    backend: {
      loadPath: "{{lng}}|{{ns}}",
      request: async (options, url, payload, callback) => {
        try {
          const [lng] = url.split("|");
          const resources = await loadTranslations(lng);
          callback(null, {
            data: resources[lng] || {},
            status: 200,
          });
        } catch (e) {
          console.error(e);
          callback(null, {
            status: 500,
          });
        }
      },
    },
    fallbackLng: "es",
    debug: true,
    load: "languageOnly",
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
  });

i18n.languages = ["en", "es"];

// Delayed language change for testing purposes
i18n.changeLanguage("es");
setTimeout(() => {
  // alert("Language Changed");
}, 5000);

export default i18n;
