/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
//** Import Image */
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../../services/AxiosInstance";
import { loginConfirmedAction } from "../../../../store/actions/AuthActions";
import PageTitle from "../../../layouts/PageTitle";

const AppProfile = () => {
  const UserDetails = useSelector((state) => state.auth.auth);
  const history = useNavigate("");
  const dispatch = useDispatch();
  const fieldNames = [
    "establishment",
    "address",
    "town",
    "telephone",
    "license",
    "license_status",
    "type",
    "sub_type",
    "contact_person",
    "license_type",
    "license_expiration",
    "capacity",
  ];
  const [password, setPassword] = useState();
  const [formData, setFormData] = useState(
    fieldNames.reduce((acc, fieldName) => {
      acc[fieldName] = "";
      return acc;
    }, {})
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);

  const ref = useRef();

  const onClick = () => {
    ref.current.click();
  };

  const imgUpload = (image) => {
    setLoader(true);

    const formdata = new FormData();
    formdata.append("image", image[0]);
    axiosInstance
      .post(`carehome/update/profile_pic`, formdata)
      .then((response) => {
        if (response.data.status === true) {
          toast.success(response.data.response);
          userData();
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((errors) => {
        setLoader(false);

        console.log(errors, "errors");
      });
  };
  const DeleteImg = () => {
    setLoader(true);
    axiosInstance
      .delete(`carehome/delete/profile_pic`)
      .then((response) => {
        if (response.data.status === true) {
          toast.success(response.data.response);
          setLoader(false);
          userData();
        } else {
          setLoader(false);
        }
      })
      .catch((errors) => {
        setLoader(false);

        console.log(errors, "errors");
      });
  };
  const Deletedocxs = (id) => {
    setLoader(true);

    axiosInstance
      .delete(`carehome/delete/document/${id}`)
      .then((response) => {
        if (response.data.status === true) {
          toast.success(response.data.response);
          userData();
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((errors) => {
        setLoader(false);

        console.log(errors, "errors");
      });
  };

  const userData = () => {
    setLoader(true);
    axiosInstance
      .get(`carehomes/${UserDetails.localId}`)
      .then((response) => {
        console.log(response);
        const Data = response.data.data;
        if (response.data.status === true) {
          setData(Data);
          setFormData(Data);
          setLoader(false);
          dispatch(loginConfirmedAction(response?.data));
        } else {
          setLoader(false);
        }
      })
      .catch((errors) => {
        console.log(errors);
        setLoader(false);
      });
  };
  useEffect(() => {
    if (UserDetails.is_subscribed === true) {
      userData();
    } else {
      history("/packages");
    }
  }, []);
  const viewPicture = () => {
    window.open(data.image, "_blank");
  };
  const [docxs, setDocxs] = useState([{ documentType: "", file: null }]);

  const handleDocumentTypeChange = (index, value) => {
    const updatedDocxs = [...docxs];
    updatedDocxs[index].documentType = value;
    setDocxs(updatedDocxs);
  };

  const handleFileChange = (index, e) => {
    const file = e.target.files[0];
    const updatedDocxs = [...docxs];
    updatedDocxs[index].file = file;
    setDocxs(updatedDocxs);
  };

  const addDocx = () => {
    const updatedDocxs = [...docxs, { documentType: "", file: null }];
    setDocxs(updatedDocxs);
  };

  const removeDocx = (index) => {
    const updatedDocxs = [...docxs];
    updatedDocxs.splice(index, 1);
    setDocxs(updatedDocxs);
  };

  const removeAllDocxs = () => {
    setDocxs([{ documentType: "", file: null }]);
  };

  const isResume = (index) => {
    return docxs[index].documentType === "resume";
  };

  const isPDF = (file) => {
    return file && file.type === "application/pdf";
  };
  const AddDocuments = () => {
    setLoader(true);

    const formdata = new FormData();
    docxs.map((e, i) => formdata.append(`media[${i}][type]`, e.documentType));
    docxs.map((e, i) => formdata.append(`media[${i}][document]`, e.file));
    axiosInstance
      .post("carehome/upload/media", formdata)
      .then((response) => {
        if (response.data.status === true) {
          toast.success(response.data.response);
          userData();
          setDocxs([{ documentType: "", file: null }]);
          removeAllDocxs();
          setLoader(false);
        } else {
          toast.error(response.data.error);
          setLoader(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoader(false);
      });
  };
  const onUpdate = () => {
    setLoader(true);
    const urlencoded = new URLSearchParams();
    for (const key in formData) {
      if (Object.hasOwnProperty.call(formData, key)) {
        urlencoded.append(key, formData[key]);
      }
      urlencoded.append("password", password);
    }

    axiosInstance
      .put(`carehome/update/${UserDetails.localId}`, urlencoded)
      .then((response) => {
        if (response.data.status === true) {
          toast.success(response.data.response);
          userData();
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((errors) => {
        console.log(errors);
        setLoader(false);
      });
  };
  const { t } = useTranslation();
  return (
    <Fragment>
      {loader ? <div className="loader"></div> : null}
      <PageTitle activeMenu="Profile" motherMenu="App" />

      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content ">
                <div className="cover-photo rounded"></div>
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <Dropdown className="dropdown ms-auto">
                    <Dropdown.Toggle
                      //   variant="primary"
                      className="bg-transparent border-0"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <img
                        src={data?.image}
                        className=" rounded-circle"
                        alt="profile"
                        height={87}
                        width={87}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                      <Dropdown.Item
                        className="dropdown-item text-capitalize"
                        onClick={viewPicture}
                      >
                        <i className="fa fa-user-circle text-primary  me-2" />
                        {t("view_profile_picture").replace(/_/g, " ")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="dropdown-item text-capitalize"
                        onClick={onClick}
                      >
                        <i className="fa fa-plus text-primary  me-2" />
                        {t("add_new_profile_picture").replace(/_/g, " ")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="dropdown-item text-capitalize"
                        onClick={DeleteImg}
                      >
                        <i className="fa fa-trash text-danger me-2" />
                        {t("delete")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <input
                  type="file"
                  ref={ref}
                  className="d-none"
                  onChange={(e) => {
                    imgUpload(e.target.files);
                  }}
                />
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0 text-capitalize">
                      {data?.establishment}
                    </h4>
                    <p>{t("care_homes").replace(/_/g, " ")}</p>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">{data?.email}</h4>
                    <p>{t("email")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <div className="tab-content">
                    <div
                      id="profile-settings"
                      className={`tab-pane fade active show`}
                    >
                      <div className="pt-3">
                        <div className="settings-form">
                          <h4 className="text-primary">
                            {t("account_setting").replace(/_/g, " ")}
                          </h4>
                          <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row">
                              <div className="form-group mb-3 col-md-6">
                                <label className="form-label text-capitalize">
                                  {t("email")}
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  value={formData.email}
                                  disabled
                                />
                              </div>
                              <div className="form-group mb-3 col-md-6">
                                <label className="form-label text-capitalize">
                                  {t("password")}
                                </label>
                                <input
                                  type="password"
                                  className="form-control"
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                              </div>
                              {fieldNames.map((e) => (
                                <div className="form-group mb-3 col-md-6">
                                  <label className="form-label text-capitalize">
                                    {t(e).replace(/_/g, " ")}
                                  </label>
                                  {e === "type" ? (
                                    <select
                                      key={e}
                                      className="form-control"
                                      name={e}
                                      value={formData[e]}
                                      onChange={handleInputChange}
                                    >
                                      <option
                                        selected
                                        hidden
                                        value={formData[e]}
                                      >
                                        {formData[e].replace(/_/g, " ")}
                                      </option>
                                      <option value={"older_adults"}>
                                        Older Adults
                                      </option>
                                    </select>
                                  ) : e === "sub_type" ? (
                                    <select
                                      key={e}
                                      name={e.state}
                                      value={formData[e]}
                                      className="form-control "
                                      onChange={handleInputChange}
                                    >
                                      <option
                                        selected
                                        hidden
                                        value={formData[e]}
                                      >
                                        {formData[e].replace(/_/g, " ")}
                                      </option>
                                      <option value={"adult_institution"}>
                                        Adult Institution
                                      </option>
                                      <option value={"campea"}>campea</option>
                                    </select>
                                  ) : e === "license_type" ? (
                                    <select
                                      key={e}
                                      name={e.state}
                                      value={formData[e]}
                                      className="form-control "
                                      onChange={handleInputChange}
                                    >
                                      <option
                                        selected
                                        hidden
                                        value={formData[e]}
                                      >
                                        {formData[e].replace(/_/g, " ")}
                                      </option>
                                      <option value={"licensed"}>
                                        License
                                      </option>
                                    </select>
                                  ) : (
                                    <input
                                      type={
                                        e === "license_expiration"
                                          ? "date"
                                          : e === "capacity"
                                          ? "number"
                                          : e === "email"
                                          ? "email"
                                          : e === "password"
                                          ? "password"
                                          : "text"
                                      }
                                      defaultValue={data[e]}
                                      className="form-control"
                                      name={e}
                                      value={formData[e]}
                                      onChange={handleInputChange}
                                    />
                                  )}
                                </div>
                              ))}
                            </div>

                            <div></div>
                            <div className="row">
                              <div
                                className={
                                  data?.media !== null
                                    ? "col-md-5"
                                    : "col-md-12"
                                }
                              >
                                <h3
                                  className="form-check-label text-center my-3"
                                  htmlFor="gridCheck"
                                >
                                  Upload Documents
                                </h3>
                                {docxs?.map((docx, index) => {
                                  return (
                                    <div className="row" key={index}>
                                      <div className="form-group mb-3 col-md-5 col-sm-5">
                                        <label className="form-label">
                                          Select Document Type
                                        </label>
                                        <select
                                          onChange={(e) => {
                                            handleDocumentTypeChange(
                                              index,
                                              e.target.value
                                            );
                                          }}
                                          className="form-control"
                                        >
                                          <option selected disabled hidden>
                                            Select Document Type....
                                          </option>
                                          <option value="hospital">
                                            Hospital
                                          </option>
                                          <option value="personal">
                                            Personal
                                          </option>
                                          <option value="resume">Resume</option>
                                        </select>
                                      </div>
                                      <div className="form-group mb-3 col-md-6 col-sm-6">
                                        <label className="form-label">
                                          {t("upload_document")}
                                        </label>
                                        <input
                                          type="file"
                                          className="form-control p-3"
                                          accept={
                                            isResume(index)
                                              ? "application/pdf"
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleFileChange(index, e)
                                          }
                                        />
                                        {isResume(index) &&
                                          !isPDF(docx.file) && (
                                            <p className="text-capitalize text-danger">
                                              {t(
                                                "please_select_a_pdf_file_for_the_resume."
                                              )}
                                            </p>
                                          )}
                                      </div>
                                      {index === 0 ? (
                                        <div className="col-md-1 col-sm-1">
                                          <button
                                            onClick={addDocx}
                                            className="btn btn-outline-primary mt-4"
                                          >
                                            <i className="fa fa-plus" />{" "}
                                          </button>
                                        </div>
                                      ) : (
                                        <div className="col-md-1 col-sm-1">
                                          <button
                                            className="btn btn-outline-danger mt-4"
                                            onClick={() => removeDocx(index)}
                                          >
                                            <i className="fa fa-trash" />{" "}
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                                <div className="float-end">
                                  <button
                                    className="btn btn-primary"
                                    onClick={AddDocuments}
                                  >
                                    Add Documents
                                  </button>
                                </div>
                              </div>
                              {data?.media !== null ? (
                                <>
                                  <div className="col-md-1" />
                                  <div className="col-md-6">
                                    <h3
                                      className="form-check-label text-center my-3"
                                      htmlFor="gridCheck"
                                    >
                                      {t("uploaded_documents")}
                                    </h3>
                                    <div className="row">
                                      {data?.media?.map((e) => {
                                        return (
                                          <>
                                            <div
                                              className="col-md-3"
                                              style={{ position: "relative" }}
                                            >
                                              <label
                                                htmlFor=""
                                                className="form-check-label text-center my-3 text-capitalize"
                                              >
                                                {e.type}
                                              </label>
                                              <br />
                                              <a
                                                href={e.document}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {e.type !== "resume" ? (
                                                  <img
                                                    src={e.document}
                                                    alt=""
                                                    className="img-thumbnail w-100 text-center"
                                                    height={50}
                                                  />
                                                ) : (
                                                  <i
                                                    className="fa fa-file-pdf text-danger text-center"
                                                    style={{ fontSize: 50 }}
                                                  ></i>
                                                )}
                                              </a>
                                              <i
                                                className="fa fa-times btn-danger fs-6 rounded-circle p-1"
                                                onClick={() =>
                                                  Deletedocxs(e.id)
                                                }
                                                style={{
                                                  position: "absolute",
                                                  top: "12px",
                                                  right: "30px",
                                                }}
                                              />
                                            </div>
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="text-center">
                                  <button
                                    className="btn btn-primary w-50 mt-5"
                                    onClick={onUpdate}
                                  >
                                    Update
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AppProfile;
