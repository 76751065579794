import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../services/AxiosInstance";
import { loadingToggleAction } from "../../store/actions/AuthActions";
import loginbg from "../../images/pic1.png";


function Register(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  let errorsObj = {
    email: "",
    address: "",
    password: "",
    director: "",
    confrimpassword: "",
    town: "",
    telephone: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const [confrimpassword, setconfrimPassword] = useState("");
  const [director, setdirector] = useState("");
  const [address, setAddress] = useState("");
  const [town, setTown] = useState("");
  const [telephone, setTelephone] = useState("");
  const [license, setlicense] = useState("");
  const [license_status, setlicense_status] = useState("");
  const [ability, setability] = useState("");
  const [pricing, setpricing] = useState("");
  const [checkemail, setcheckemail] = useState("");
  const onChange = (setState) => (e) => {
    setState(e.target.value);
    console.log(setState, e, "setState");
  };
  const dispatch = useDispatch();

  function onSignUp() {
    let error = false;
    const errorObj = { ...errorsObj };

    if (director === "") {
      errorObj.director = "Last Name is Required";
      error = true;
    }
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (address === "") {
      errorObj.address = "Address is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    if (confrimpassword === "") {
      errorObj.confrimpassword = "ConfirmPassword is Required";
      error = true;
    }
    if (confrimpassword !== password) {
      errorObj.confrimpassword = "ConfirmPassword Mismatch!";
      error = true;
    }
    if (town === "") {
      errorObj.town = "Select Job Category";
      error = true;
    }
    if (telephone === "") {
      errorObj.telephone = "Select Care Homes";
      error = true;
    }
    if (checkemail === 1) {
      alert("error");
    }
    setErrors(errorObj);
    if (error) return;
    dispatch(loadingToggleAction(true));

    const data = {
      telephone: telephone,
      director: director,
      town: town,
      password: password,
      email: email,
      address: address,
      license: license,
      license_status: license_status,
      ability: ability,
      pricing: pricing,
    };
    localStorage.setItem("data", JSON.stringify(data));
    navigate("/packages");
  }
  const accessemail = () => {
    const formdata = new FormData();
    formdata.append("email", email);
    axiosInstance
      .post("find_by_email", formdata)
      .then((e) => {
        const Data = e.data;
        console.log(e, Data, "Response");
        if (Data === 1) {
          setcheckemail("This Email is already Exists");
        } else {
          onSignUp();
          setcheckemail("");
        }
      })
      .catch((e) => console.log(e));
  };
  

  return (
    <div style={{ backgroundImage: "url(" + loginbg + ")",objectFit:'contain',backgroundSize:'100%' }}>
    <div className="container">
      <div className="row">
        <div className="offset-md-3 col-md-6">
          <div className="authincation-content">
            <div className="row no-gutters">
              <div className="col-xl-12">
                <div className="p-5">
                  <h4 className="text-center mb-4 ">Sign up your account</h4>
                  <div className="row">
                    <div className="form-group  col-md-12 mb-3">
                      <label className="mb-1 ">
                        <strong>Director</strong>
                      </label>
                      <input
                        value={director}
                        onChange={onChange(setdirector)}
                        className="form-control"
                        type="text"
                      />
                      {errors.director && (
                        <div className="text-danger">{errors.director}</div>
                      )}
                    </div>
                    <div className="form-group col-md-12 mb-3">
                      <label className="mb-1 ">
                        <strong>Email</strong>
                      </label>
                      <input
                        onChange={onChange(setEmail)}
                        value={email}
                        className="form-control"
                        placeholder="hello@example.com"
                        type="email"
                      />
                      {errors.email && (
                        <div className="text-danger">{errors.email}</div>
                      )}
                      {checkemail && (
                        <div className="text-danger">{checkemail}</div>
                      )}
                    </div>
                    <div className="form-group col-md-12 mb-3">
                      <label className="mb-1 ">
                        <strong>Address</strong>
                      </label>
                      <input
                        onChange={onChange(setAddress)}
                        value={address}
                        className="form-control"
                        type="text"
                      />
                      {errors.address && (
                        <div className="text-danger">{errors.address}</div>
                      )}
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label className="mb-1">
                        <strong>Telephone</strong>
                      </label>
                      <input
                        type="tel"
                        name=""
                        id=""
                        className="form-control"
                        value={telephone}
                        onChange={onChange(setTelephone)}
                      />
                      {errors.telephone && (
                        <div className="text-danger">{errors.telephone}</div>
                      )}
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label className="mb-1">
                        <strong>Town</strong>
                      </label>
                      <input
                        value={town}
                        onChange={onChange(setTown)}
                        type="text"
                        className="form-control"
                        defaultValue="Confirm Password"
                      />
                      {errors.town && (
                        <div className="text-danger">{errors.town}</div>
                      )}
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label className="mb-1">
                        <strong>License</strong>
                      </label>
                      <input
                        onChange={onChange(setlicense)}
                        className="form-control"
                        type="text"
                        value={license}
                      />
                      {/* {errors.jobcatory && (
                          <div className="text-danger">{errors.jobcatory}</div>
                        )} */}
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label className="mb-1">
                        <strong>License Status</strong>
                      </label>
                      <input
                        onChange={onChange(setlicense_status)}
                        className="form-control"
                        type="text"
                        value={license_status}
                      />
                      {/* {errors.jobcatory && (
                          <div className="text-danger">{errors.jobcatory}</div>
                        )} */}
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label className="mb-1">
                        <strong>Ability</strong>
                      </label>
                      <input
                        onChange={onChange(setability)}
                        className="form-control"
                        type="text"
                        value={ability}
                      />
                      {/* {errors.jobcatory && (
                          <div className="text-danger">{errors.jobcatory}</div>
                        )} */}
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label className="mb-1">
                        <strong>Price</strong>
                      </label>
                      <input
                        onChange={onChange(setpricing)}
                        className="form-control"
                        type="number"
                        value={pricing}
                      />
                      {/* {errors.jobcatory && (
                          <div className="text-danger">{errors.jobcatory}</div>
                        )} */}
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label className="mb-1 ">
                        <strong>Password</strong>
                      </label>
                      <input
                        onChange={onChange(setPassword)}
                        value={password}
                        className="form-control"
                        defaultValue="Password"
                        type="password"
                      />
                      {errors.password && (
                        <div className="text-danger">{errors.password}</div>
                      )}
                    </div>

                    <div className="form-group col-md-6 mb-3">
                      <label className="mb-1">
                        <strong>Confirm Password</strong>
                      </label>
                      <input
                        onChange={onChange(setconfrimPassword)}
                        value={confrimpassword}
                        className="form-control"
                        defaultValue="Confirm Password"
                        type="password"
                      />
                      {errors.confrimpassword && (
                        <div className="text-danger">
                          {errors.confrimpassword}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="text-center mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      onClick={accessemail}
                    >
                      Sign me up
                    </button>
                  </div>
                  <div className="new-account mt-3">
                    <p className="">
                      Already have an account?{" "}
                      <Link className="text-primary" to="/login">
                        Sign in
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Register);
