import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstance } from "../../services/AxiosInstance";
import { useTranslation } from "react-i18next";

const Addblueprint = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [highlights, setHighlights] = useState([]);
  const [showData, setShowData] = useState(false);
  const [no_floor, setNo_floor] = useState("");
  const [blueprint, setBluePrint] = useState("");
  const onShowData = () => setShowData(true);
  const onHideData = () => setShowData(false);
  const { floor } = useParams();
  // const User = useSelector((state) => state.auth.auth);
  const addFloor = () => {
    setLoader(true);
    const formdata = new FormData();
    formdata.append("blueprint", blueprint);
    formdata.append("floor", no_floor);
    highlights.map((e, i) => formdata.append(`beds[${i}][title]`, e.name));
    highlights.map((e, i) => formdata.append(`beds[${i}][x]`, e.x));
    highlights.map((e, i) => formdata.append(`beds[${i}][y]`, e.y));
    axiosInstance
      .post("carehome/blueprint", formdata)
      .then(function (response) {
        console.log(response, "Getting Compaines");
        if (response?.data.status === true) {
          toast.success(response.data.message);
          setShowData(false);
          setBluePrint("");
          setNo_floor("");
          setHighlights([]);
          navigate("/floor");
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setBluePrint(file); // Optionally, you can store the file itself in the state.
      setImageUrl(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      onHideData();
    }
  };
  const handleImageClick = (event) => {
    const { offsetX, offsetY } = event.nativeEvent;
    const image = event.target;
    const xPercent = (offsetX / image.clientWidth) * 100 - 5; // Subtract 5% from the left
    const yPercent = (offsetY / image.clientHeight) * 100 - 2; // Subtract 3% from the top
    const data = prompt("Enter the Number of Bed");

    if (data === "") {
      toast.error("Enter Bed Name");
    } else if (data === null) {
    } else {
      // Add the new bed highlight
      setHighlights([...highlights, { x: xPercent, y: yPercent, name: data }]);
      toast.success(`Bed Added`);
    }
  };

  const handleBedIconClick = (clickedBedName) => {
    // Filter out the clicked bed from the highlights
    const updatedHighlights = highlights.filter(
      (highlight) => highlight.name !== clickedBedName
    );

    // Update the highlights state
    setHighlights(updatedHighlights);
    toast.success(`Bed "${clickedBedName}" Removed`);
  };

  useEffect(() => {
    onShowData();
  }, []);
  console.log(highlights);
  const { t, i18n } = useTranslation();
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="container">
        {floor!=="undefined" && <h1 className="text-center mb-2">{floor}</h1>}
        <div className="row">
          <h4 className="my-4">
            {no_floor}
            {t("floors")}
          </h4>
          <div className="col-md-12">
            {imageUrl && (
              <div style={{ position: "relative" }}>
                <img
                  src={imageUrl}
                  alt="Your Iage"
                  onClick={handleImageClick}
                  style={{
                    cursor: "crosshair",
                    width: "100%",
                    height: 400,
                  }}
                />
                {/* Render the highlights */}
                {highlights.map((highlight, index) => (
                  <div
                    key={index}
                    style={{
                      left: `${highlight.x}%`,
                      top: `${highlight.y}%`,
                      position: "absolute",
                      height: "50px",
                      width: "50px",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <img
                      title={`${highlight?.title} ${t("bed")}`}
                      onClick={() => handleBedIconClick(highlight.name)}
                      src={
                        i18n.language === "en"
                          ? "/bed/Available.png"
                          : "/bed/Available2.png"
                      }
                      style={{
                        height: 50,
                        width: 50,
                        cursor: "pointer",
                        margin: 0,
                        padding: 0,
                      }}
                      className="shake-on-hover "
                      alt=""
                    />
                  </div>
                ))}
              </div>
            )}
            <div className="float-end mt-5">
              <div className="btn btn-outline-success " onClick={addFloor}>
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <Modal show={showData} onHide={() => setShowData(false)} size="lg">
        <ModalHeader>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <h4 className="mt-3">{t("add_blueprint")}</h4>
              </div>
              <div className="col-md-6">
                <div className="float-right"></div>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="">Floor</label>
                <input
                  type="number"
                  placeholder="Enter Number of Floor"
                  className="form-control "
                  onChange={(e) => setNo_floor(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="">Upload Blue Print</label>
                <input
                  type="file"
                  className="form-control p-2 "
                  onChange={handleFileUpload}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="btn btn-outline-danger" onClick={onHideData}>
            Close
          </div>
          <div className="btn btn-outline-success" onClick={addFloor}>
            Submit
          </div>
        </ModalFooter>
      </Modal>
      <style>{`@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(5px); }
}

/* Apply the shake animation to the icon on hover */
.shake-on-hover:hover {
  animation: shake 0.5s ease-in-out;
}`}</style>
    </>
  );
};

export default Addblueprint;
