/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../services/AxiosInstance";
import { loginConfirmedAction } from "../../store/actions/AuthActions";
import "../packages.css";
//import { Link } from "react-router-dom";
// image
//import logo from "../../images/logo-full.png";
const Packges = ({ history }) => {
  const navigate = useNavigate();
  const User = useSelector((state) => state.auth.auth);
  const [packages, setPackage] = useState([]);
  const [coupon, setCoupon] = useState("");
  const [loader, setLoader] = useState(false);
  const Signup = (id) => {
    setLoader(true);
    const formdata = new FormData();
    formdata.append("coupon", coupon);
    formdata.append("type", "carehome");
    formdata.append("plan_id", id);
    formdata.append("carehome", User.localId);
    axiosInstance
      .post("carehomes/subscribe", formdata)
      .then((e) => {
        if (e.data.status === true) {
          window.location.href = e.data.data;
          setLoader(false);
        } else setLoader(false);
      })
      .catch((e) => setLoader(false));
  };
  console.log(User.is_subscribed);
  console.log(User, "user");
  const dispatch = useDispatch("");
  const userData = () => {
    setLoader(true);
    axiosInstance
      .get(`carehomes/${User.localId}`)
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          setLoader(false);
          if (response?.data?.data.is_subscribed === true) {
            navigate("/app-profile");
          } else {
            setLoader(true);
            axiosInstance
              .get("/plans")
              .then((e) => {
                console.log(e);
                if (e.data.status === true) {
                  setPackage(e.data.data);
                  setLoader(false);
                } else setLoader(false);
              })
              .catch((e) => setLoader(false));
          }
          dispatch(loginConfirmedAction(response?.data));
        } else {
          setLoader(false);
        }
      })
      .catch((errors) => {
        console.log(errors);
        setLoader(false);
      });
  };
  useEffect(() => {
    userData();
  }, []);
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div style={{ backgroundColor: "rgba(19,98,252,.1)" }}>
        <div className="container p-3">
          <h2 className="text-center mt-3">
            Here are some <span className="title-colot"> Packages</span>
          </h2>{" "}
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Enter Coupon Code"
                onChange={(e) => setCoupon(e.target.value)}
              />
            </div>
          </div>
          <div className="row mt-5 mb-5">
            {packages
              .filter((e) => e.type === "Carehome")
              .map((e) => {
                return (
                  <div className="col-md-6 col-sm-6 col-lg-4">
                    <div className="plan ">
                      <p className="plan-title">{e.title}</p>

                      <p className="plan-price">
                        {e.price}
                        <span>{e.duration}/Days</span>
                      </p>
                      <button className="btn mt-4" onClick={() => Signup(e.id)}>
                        Buy Now
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Packges;
