/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../services/AxiosInstance";
import "./bed.css";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const Bed_availability = () => {
  const { id } = useParams();
  const { floor } = useParams();
  const { floor_id } = useParams();
  const [loader, setLoader] = useState(false);
  const [showData, setShowData] = useState(false);
  const onShowData = () => setShowData(true);
  const onHideData = () => setShowData(false);
  const [viewbeds, setViewBeds] = useState([]);
  const [beds, setBeds] = useState([]);
  const title = useSelector((state) => state.auth.auth);
  console.log(title, "title");
  const getBeds = () => {
    setLoader(true);
    axiosInstance
      .get(`carehomes/${id}`)
      .then(function (response) {
        console.log(response, "floors");
        if (response?.data.status === true) {
          setBeds(response?.data?.data?.buildings[0].floors);
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log(error, "rorr");
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };

  useEffect(() => {
    getBeds();
  }, []);
  const FindBed = (id) => {
    beds.forEach((item) => {
      item.beds.forEach((bed) => {
        if (bed.id === id) {
          setViewBeds(bed);
          onShowData();
        }
      });
    });
  };
  const DeleteBed = (id) => {
    setLoader(true);
    axiosInstance
      .delete(`carehome/delete_bed/${id}`)
      .then((response) => {
        console.log(response, "delete");
        if (response.data === 1) {
          toast.success(`Bed SuccessFully Deleted !!`);
          getBeds();
          onHideData();
          setLoader(false);
        } else {
          toast.error("SomeThing Went Wrong");
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };
  const { i18n } = useTranslation();
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="container">
        <h1 className="text-center mb-2">{title?.director}</h1>
        <div className="row">
          <h4 className="my-4">{floor} Floor</h4>

          <div className="col-md-12">
            {beds &&
              beds
                .filter((s) => Number(s.id) === Number(floor_id))
                .map((e) => {
                  return (
                    <>
                      <div style={{ position: "relative" }}>
                        <img
                          src={e.blueprint.document}
                          alt="Your Iage"
                          style={{
                            cursor: "crosshair",
                            width: "100%",
                            height: 400,
                          }}
                        />
                        {/* Render the highlights */}
                        {e.beds.map((highlight, index) => (
                          <div
                            style={{
                              left: `${highlight.x}%`,
                              top: `${highlight.y}%`,
                              position: "absolute",
                              height: "50px",
                              width: "50px",
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            <img
                              title={`${highlight.title} bed`}
                              onClick={() => FindBed(highlight.id)}
                              src={
                                i18n.language === "en"
                                  ? "/bed/Available.png"
                                  : "/bed/Available2.png"
                              }
                              style={{
                                height: 50,
                                width: 50,
                                cursor: "pointer",
                                margin: 0,
                                padding: 0,
                              }}
                              className="shake-on-hover"
                              alt=""
                            />
                          </div>
                          // <div
                          //   key={index}
                          //   className="highlight"
                          //   title={highlight.name}
                          //   style={{ left: highlight.x, top: highlight.y, borderColor: 'red', borderWidth: 1, height: '10px', width: '10px', position: 'absolute' }}
                          // />
                        ))}
                      </div>
                    </>
                  );
                })}
          </div>
        </div>
      </div>{" "}
      <Modal show={showData} onHide={() => setShowData(false)} size="md">
        <ModalHeader>
          <div className="col-md-12">
            <div className="row">
              <h4 className="mt-3">Bed</h4>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              <h5>This is {viewbeds.title} bed</h5>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="btn btn-outline-light" onClick={onHideData}>
            Close
          </div>
          <div
            className="btn btn-outline-danger"
            onClick={() => DeleteBed(viewbeds.id)}
          >
            Delete
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Bed_availability;
